import { render, staticRenderFns } from "./list_delete.vue?vue&type=template&id=4172ff34&scoped=true&"
var script = {}
import style0 from "./list_delete.vue?vue&type=style&index=0&id=4172ff34&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4172ff34",
  null
  
)

export default component.exports