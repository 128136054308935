import { render, staticRenderFns } from "./c-pattern-operator-chip.vue?vue&type=template&id=1153ae14&scoped=true&lang=pug&"
var script = {}
import style0 from "./c-pattern-operator-chip.vue?vue&type=style&index=0&id=1153ae14&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1153ae14",
  null
  
)

export default component.exports