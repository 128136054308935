import { render, staticRenderFns } from "./v-checkbox-functional.vue?vue&type=template&id=517ad016&scoped=true&lang=pug&"
import script from "./v-checkbox-functional.vue?vue&type=script&lang=js&"
export * from "./v-checkbox-functional.vue?vue&type=script&lang=js&"
import style0 from "./v-checkbox-functional.vue?vue&type=style&index=0&id=517ad016&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "517ad016",
  null
  
)

export default component.exports