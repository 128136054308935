import { render, staticRenderFns } from "./popup-item.vue?vue&type=template&id=3a2739e0&scoped=true&lang=pug&"
import script from "./popup-item.vue?vue&type=script&lang=js&"
export * from "./popup-item.vue?vue&type=script&lang=js&"
import style0 from "./popup-item.vue?vue&type=style&index=0&id=3a2739e0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2739e0",
  null
  
)

export default component.exports