export default {
  tabs: {
    parameters: 'Paramètres',
    importExportViews: 'Import/Export',
    stateSettings: 'Paramètres d\'état',
    storageSettings: 'Paramètres de stockage',
    notificationsSettings: 'Paramètres des notifications',
    widgetTemplates: 'Modèles de widgets',
  },
};
