import { render, staticRenderFns } from "./alarms-list-table-with-pagination.vue?vue&type=template&id=1030e2f8&lang=pug&"
import script from "./alarms-list-table-with-pagination.vue?vue&type=script&lang=js&"
export * from "./alarms-list-table-with-pagination.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports