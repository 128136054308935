import { render, staticRenderFns } from "./alarm-header-priority.vue?vue&type=template&id=6dd4bfd8&scoped=true&lang=pug&"
var script = {}
import style0 from "./alarm-header-priority.vue?vue&type=style&index=0&id=6dd4bfd8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dd4bfd8",
  null
  
)

export default component.exports