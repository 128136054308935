export default {
  ticketUrlAndId: 'Ticket URL and ID',
  ticketUrlAndIdHelpText: 'The ticket URL received from the API response can be added to the alarm dynamic infos.',
  dataFromOneStepAttention: 'Data from the API response can be taken only from one step of a rule.',
  ticketID: 'Ticket ID',
  ticketURL: 'Ticket URL',
  allowTicketURLTemplate: 'Allow variables in the URL',
  alarmFieldName: 'Alarm field name',
  customFields: 'Custom fields',
  responseField: 'Response field',
  responseFieldHelpText: 'API Response field in JSON format where the "{field}" is taken from',
  emptyResponse: 'Empty response',
  isRegexp: 'The value can be a RegExp',
  addWebhook: 'Add webhook',
  emptyFields: 'No fields added yet',
  emptyWebhooks: 'No webhooks added yet',
  workflowIfStepFails: 'If this step fails:',
  continueWithNextStep: 'Continue with next steps',
  testQuery: 'Test query',
  webhookTicketDeclarationExist: 'Webhook ticket declaration already exist in another step',
  runTest: 'Run test',
  headerFieldFromPreviousSteps: 'Header field from previous steps',
  responseFieldFromStep: 'Response field from the step #N',
  responseFieldFromPreviousSteps: 'Response field from previous steps',
  userId: 'User ID',
  actionInitiator: 'Action initiator',
  triggerEventMessage: 'Trigger event message',
  noRulesForAlarms: 'No rules to apply for the selected alarms',
  oneTicketForAlarms: 'One ticket for all alarms',
  applyRules: 'Apply rules',
  webhookStatus: 'Webhook status',
  webhookComplete: 'Webhook complete',
  noAckItems: 'There is {count} item without ack. Ack event for the item will send before. | There is {count} items without ack. Ack events for items will send before.',
  ruleName: 'Rule name',
  checkSyntax: 'Check syntax',
  syntaxIsValid: 'Syntax is valid',
  ticketResources: 'Declare ticket for resources',
  noResponse: 'No response',
  errors: {
    webhookRequired: 'The webhooks must be added',
    webhookTicketDeclarationRequired: 'Some one webhook ticket declaration must be set',
    ticketRequired: 'Some one ticket must be add',
  },
};
